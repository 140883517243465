import {useEffect,  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
const HomeOne = lazy(() => import("./pages/HomeOne"));
const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const HomeThree = lazy(() => import("./pages/HomeThree"));
const About = lazy(() => import("./pages/About"));
// const Service = lazy(() => import("./pages/Service"));
const Work = lazy(() => import("./pages/Work"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogClassic = lazy(() => import("./pages/BlogClassic"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));
const Contact = lazy(() => import("./pages/Contact"));
const NotFound = lazy(() => import("./pages/NotFound"))
const StaffAugmentationBlog = lazy(() => import("./container/BlogGrid/StaffAugBlogContainer"))

const CloudMigration = lazy(()=>import("./pages/servicesPages/CloudMigration"));
const ArtificialIntelligence = lazy(()=>import("./pages/servicesPages/ArtificialIntelligence"));
const EntSoftDev = lazy(()=>import("./pages/servicesPages/EntSoftDev"));
const QA = lazy(()=>import("./pages/servicesPages/QA"));
const StaffAugmentation = lazy(()=>import("./pages/servicesPages/StaffAugmentation"));
const UIDesign = lazy(()=>import("./pages/servicesPages/UIDesign"));


function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
                <Routes>
                  <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-one"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} element={<HomeTwo/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} element={<HomeThree/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/services/*"}`} element={<ServiceRoutes/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/work"}`} element={<Work/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} element={<WorkDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blogs"}`} element={<BlogGrid/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} element={<BlogClassic/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} element={<BlogTag/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} element={<BlogCategories/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}element={<BlogDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/benefits-of-staff-augmentation"}`} element={<StaffAugmentationBlog/>} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </NavScrollTop>
      </Router>
  );
}

function ServiceRoutes() {
  return (
    <Routes>
      {/* <Route path="/" element={<Service />} />  */}
      <Route path="/cloud-services-and-migration" element={<CloudMigration />} />
      <Route path="/artificial-intelligence" element={<ArtificialIntelligence />} />
      <Route path="/enterprise-software-development" element={<EntSoftDev />} />
      <Route path="/quality-assurance" element={<QA />} />
      <Route path="/staff-augmentation" element={<StaffAugmentation />} />
      <Route path="/user-interface-and-user-experience" element={<UIDesign />} />
    </Routes>
  );
}

export default App;
